.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*{
  font-family: 'Poppins', sans-serif;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  background: #FAFEFF;
  height: 100vh;
}
.text-center{
  text-align: center;
}
.flex{
  display: flex;
}
.items-center{
  align-items: center;
}
.justify-center{
  justify-content: center;
}
.justify-between{
  justify-content: space-between;
}
.flex-wrap{
  flex-wrap: wrap;
}
.h-full{
  height: 100%;
}
.w-full{
  width: 100%;
}
.card{
  width: 20%;
}
.card-body{
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 20px 0px #035D690F;
  margin: 10px;
  cursor: pointer;
}
.card-body:hover{
  background-color: #035C68;
  color: #fff;
}
.p-40{
  padding: 40px;
}
.mt-10{
  margin-top: 10px;
}
.mt-0{
  margin-top: 0;
}
.mt-20{
  margin-top: 20px;
}
.themecolor{
  color: #035C68;
}

.font-normal{
  font-weight: 400;
}
.font-medium{
  font-weight: 500;
}
.modal-overlay{
  background: rgba(0,0,0,0.5);
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content{
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 500px
}
.custom-input{
  border: 1px solid #D0D5DD;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;

}
.custom-input::placeholder{
  color: #667085;
}
.prmiary-btn{
  margin-top: 10px;
  width: 100%;
  background-color: #035C68;
  color: #fff;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
}
.startFuel{
  margin-right: 10px;
}
.buttonsDiv{
  margin: 10px 0;
}
.cursor-pointer{
  cursor: pointer;
}
.p-1 {
  padding: 20px;
}
.border-b {
  border-bottom: 1px solid #eee;
}
